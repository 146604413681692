
/*
return the difference between specific dates in seconds
 */
export function timerFunc() {
    new Date().toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
    const endDate = new Date(2023, 2, 28, 17);

    const currentDate = new Date()
    var seconds = Math.ceil((endDate.getTime() - currentDate.getTime()) / (1000)) // (hours) 3600000 = 60 * 60 * 1000
    console.log("seconds =" + seconds)
    return seconds
}
